export class ScriptLoader {
  constructor(private readonly src: string) {}

  private createTag(src: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'application/javascript';
    script.async = true;
    script.src = src;

    return script;
  }

  private addListeners(
    script: Element,
    resolve: (value: void | PromiseLike<void>) => void,
    reject: (reason?: unknown) => void
  ): void {
    script.addEventListener('error', reject);
    script.addEventListener('abort', reject);
    script.addEventListener('load', () => resolve());
  }

  public load(): Promise<void> {
    return new Promise((resolve, reject) => {
      let script = document.querySelector(`script[src="${this.src}"]`);

      if (script && script.hasAttribute('data-loaded')) {
        resolve();
        return;
      }

      script = this.createTag(this.src);
      this.addListeners(script, resolve, reject);
      document.head.appendChild(script);
    });
  }
}
