<template>
  <div
    ref="marker"
    class="mtkObjectMarker maplibregl-marker"
    @click="handleMarkerClick"
  >
    <span v-if="title" class="tooltip">{{ title }}</span>
  </div>
</template>

<script lang="ts">
import { convertBaseCoordsToLatLngLike } from '../../utils/convertBaseCoords';

declare let maplibregl: unknown;

export default defineComponent({
  inject: { mtkMap: { from: 'mtkMap' } },
  props: {
    title: String,
    coords: Object,
  },
  emits: ['marker-click'],
  data() {
    return {
      marker: null,
    };
  },
  watch: {
    coords() {
      this.ensureMarker();
    },
    mtkMap() {
      if (this.marker) {
        this.marker.remove();
        this.marker = null;
      }

      this.ensureMarker();
    },
  },
  mounted() {
    this.ensureMarker();
  },
  beforeUnmount() {
    if (this.marker) {
      this.marker.remove();
      this.marker = null;
    }
  },
  methods: {
    ensureMarker() {
      if (this.mtkMap && this.coords) {
        if (this.marker) {
          this.marker.setLngLat(convertBaseCoordsToLatLngLike(this.coords));
          this.marker.setOffset([0, -24]);
        } else {
          this.marker = new maplibregl.Marker({
            element: this.$refs.marker,
            offset: [0, -24],
            draggable: false,
          })
            .setLngLat(convertBaseCoordsToLatLngLike(this.coords))
            .addTo(this.mtkMap.gl);
        }
      }
    },
    handleMarkerClick(event: unknown) {
      this.$emit('marker-click', event);
    },
  },
});
</script>

<style lang="scss" scoped>
.mtkObjectMarker {
  background: url('/assets/images/poi-marker.svg') no-repeat center center;
  background-size: contain;
  width: 48px;
  height: 48px;

  cursor: pointer;

  .tooltip {
    position: absolute;
    z-index: 2;

    visibility: hidden;

    background: #000;
    border-radius: 5px;
    padding: 5px;

    box-shadow: 0 4px 4px 0 #00000040;

    display: inline-block;

    width: initial;
    height: initial;

    white-space: nowrap;

    left: 30px;
  }

  &:hover {
    .tooltip {
      visibility: visible;
    }
  }
}
</style>
