import type { BaseCoords } from '../models/BaseCoords';

export const convertBaseCoordsToLatLngLike = (
  coords: BaseCoords | number[]
): { lat: number; lon: number } => {
  if (Array.isArray(coords)) {
    return { lat: coords[1], lon: coords[0] };
  }
  return { lat: coords.latitude, lon: coords.longitude };
};
