<template>
  <link
    rel="stylesheet"
    href="https://static.maptoolkit.net/mtk/v10.1.10/mtk.css"
  />
  <link
    rel="stylesheet"
    href="https://static.maptoolkit.net/mtk/v10.1.10/ui.css"
  />
  <link
    rel="stylesheet"
    href="https://static.maptoolkit.net/mtk/v10.1.10/elevationprofile.css"
  />

  <div class="imxMap">
    <div ref="mtkMapContainer" class="container" />

    <div class="hidden">
      <slot />
    </div>

    <slot name="below" />
  </div>
</template>

<script setup lang="ts">
import type { BaseCoords } from '../../models/BaseCoords';
import { convertBaseCoordsToLatLngLike } from '../../utils/convertBaseCoords';
import { ScriptLoader } from './script-loader';
import { onBeforeUnmount, provide, ref, shallowRef, watch } from 'vue';

declare let MTK: unknown;
declare let maplibregl: unknown;

const DEFAULT_MAP_ZOOM_LEVEL = 14;

const config = useWhlInstanceConfig();

const props = defineProps<{
  center?: BaseCoords;
  zoom?: number;
  bounds?: unknown; // as long as mtk does not provide types we have to deal with unknown values here
  enableFullscreen?: boolean;
}>();

const emit = defineEmits(['mapInit']);

const mtkMapContainer = ref<unknown>(null);
const mtkLoaded = ref<boolean>(false);
const mtkMap = shallowRef<unknown>(null);

const redrawMapBus = useEventBus<never>('redrawMap');

provide('mtkMap', mtkMap);
provide('mtkMapContainer', mtkMapContainer);

// load mtk only once
if (typeof MTK === 'undefined') {
  new ScriptLoader('https://static.maptoolkit.net/mtk/v10.1.10/mtk.js')
    .load()
    .then(() =>
      new ScriptLoader(
        'https://static.maptoolkit.net/mtk/v10.1.10/elevationprofile.js'
      ).load()
    )
    .then(() => {
      mtkLoaded.value = true;
    });
} else {
  mtkLoaded.value = true;
}

const redrawMap = () => {
  console.log('redrawing map');
  mtkMap.value?.gl.resize();
};
redrawMapBus.on(redrawMap);

const initMap = () => {
  if (mtkLoaded.value && mtkMapContainer.value) {
    const initialCenter: BaseCoords =
      props.center ?? config.value.map.defaultCoords;
    const initialZoom =
      props.zoom ?? config.value.map.defaultZoom ?? DEFAULT_MAP_ZOOM_LEVEL;

    const controls = [
      [
        new maplibregl.NavigationControl({
          showCompass: false,
          visualizePitch: false,
        }),
        'top-right',
      ],
    ];
    if (config.value.map.weatherControls) {
      controls.push([new MTK.WeatherControl(), 'top-left']);
    }
    if (props.enableFullscreen) {
      controls.push([new maplibregl.FullscreenControl(), 'top-right']);
    }

    MTK.init({ apiKey: 'infomax' }).createMap(
      mtkMapContainer.value,
      {
        map: {
          location: {
            center: convertBaseCoordsToLatLngLike(initialCenter),
            zoom: initialZoom,
          },
          controls: controls,
          mapType: 'toursprung-terrain',
          options: {
            dragRotate: false,
            pitchWithRotate: false,
          },
          smartScroll: true,
        },
      },
      (map: unknown) => {
        map.gl.scrollZoom.disable();
        map.gl.setZoom(initialZoom);
        map.gl.setCenter(convertBaseCoordsToLatLngLike(initialCenter));

        mtkMap.value = map;
        emit('mapInit');
      }
    );
  }

  /* trigger resize map, as map is not rendered correctly in fullscreen mode on Edge/Chrome */
  mtkMapContainer.value.addEventListener('fullscreenchange', redrawMap);
  mtkMapContainer.value.addEventListener('visibilitychange', redrawMap);
};

watch(mtkLoaded, initMap);
watch(mtkMapContainer, initMap);
watch(
  () => props.center,
  (center) => {
    if (center && mtkMap.value) {
      redrawMap();
      mtkMap.value.gl.flyTo({ center: convertBaseCoordsToLatLngLike(center) });
    }
  }
);
watch(
  () => props.zoom,
  (zoom) => {
    if (zoom && mtkMap.value) {
      redrawMap();
      mtkMap.value.gl.setZoom(zoom);
    }
  }
);
watch(
  () => props.bounds,
  (bounds) => {
    if (bounds && mtkMap.value) {
      redrawMap();
      mtkMap.value.gl.fitBounds(bounds, { padding: 50 });
    }
  }
);

onBeforeUnmount(() => {
  if (mtkMap.value) {
    mtkMap.value.gl.remove();
    mtkMap.value = undefined;
  }
});
</script>

<style lang="scss" scoped>
.hidden {
  display: none;
}

.imxMap {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
}

.imxMap .container {
  width: 100%;
  flex: 100%;
  background: #ccdfe3;
}
</style>
